@use '../../../styles/breakpoints.module';
@use '../../../styles/vars.module';

.custom-error-page {
  height: 100vh;
  position: relative;

  &__image-container {
    position: relative;
  }

  &__image {
    height: 100vh;
    width: 100%;

    > div {
      height: 100%;
    }

    img {
      object-fit: cover;
      object-position: center;
    }
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    gap: var(--alto-sem-space-3xl, 48px);
    left: 50%;
    max-width: 300px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    p {
      font-size: var(--alto-sem-text-body-lg-font-size);
      font-weight: 400;
      letter-spacing: var(--alto-sem-text-body-lg-letter-spacing);
      margin: 0;
    }

    @include breakpoints.for-medium-up {
      max-width: 600px;
    }
  }

  &__card-container {
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__card {
    background-color: #fff;
    border-radius: var(--space-2);
    box-shadow: vars.$box-shadow;
    display: flex;
    flex-direction: column;
    gap: var(--space-6);
    max-width: 410px;
    padding: var(--space-6);

    h2,
    p {
      margin: 0;
    }
  }

  &__card-list {
    list-style: none;
    margin: 0;
  }

  &__card-list-item {
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: var(--space-2) 0;
  }

  &__card-list-item:last-child {
    border: none;
    padding-bottom: 0;
  }

  &__card-link {
    align-items: center;
    display: flex;
  }

  &__card-link-icon {
    font-size: 1.25rem;
    padding-right: var(--space-2);
  }

  &__content-div {
    padding: var(--space-6);
  }

  &__title {
    text-align: center;
  }

  &__description {
    text-align: center;
  }
}
