$container-size: 1272px;

// Standard font sizes
$font-x-small: 0.75rem; // 12px
$font-small: 0.875rem; // 14px
$font-medium: 1rem; // 16px
$font-large: 1.25rem; // 20px
$font-x-large: 1.5rem; // 24px
$font-xx-large: 2rem; // 32px

// Grid gutters
$grid-gutter: 16px;

// Page padding
$page-side-padding-small: var(--space-2);
$page-side-padding-large: 20px;

// Borders
$box-shadow: 4px 4px 4px rgba(0, 0, 0, 10%);
$border-radius: 8px;

// easing
$ease-1: ease-in-out;
$ease-2: ease;
